let origin = window.ORIGIN;
//签到状态
const signStatusUrl = `${origin}/gateway/hc-accumulated/dailyPerf/getSignInStatus`;
//签到
const signUrl = `${origin}/gateway/hc-accumulated/dailyPerf/doSignIn`;
//每日任务
const dayMaskUrl = `${origin}/gateway/hc-accumulated/dailyPerf/getSignInStatus`;
//是否显示评论
const isShowSign = `${origin}/gateway/hc-accumulated/application/getFunctionSetting`;
//创业导师列表
const getTeacherListUrl = `${origin}/gateway/hc-business/miniapi/advisor/getList`;
//服务联盟列表
const getLeagueListUrl = `${origin}/gateway/hc-business/miniapi/league/getList`;
export {
  signStatusUrl,
  signUrl,
  dayMaskUrl,
  isShowSign,
  getTeacherListUrl,
  getLeagueListUrl,
};
